<template>
  <div>
    <v-container>
      <v-card outlined>
        <v-toolbar flat>
          <v-list-item class="pl-0 pr-0">
            <v-list-item-content>
              <v-list-item-title>{{ customer.name }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ customer.website }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-slot:extension>
            <v-tabs>
              <v-tab :to="{ name: 'customer-profile-basic' }">
                <v-icon left>mdi-account-details</v-icon>Details
              </v-tab>
              <v-tab :to="{ name: 'customer-profile-domains' }">
                <v-icon left>mdi-web</v-icon>Domains
              </v-tab>
              <v-tab :to="{ name: 'customer-profile-workload' }">
                <v-icon left>mdi-folder-open</v-icon>Workload
              </v-tab>
              <v-tab :to="{ name: 'customer-profile-finance' }">
                <v-icon left>mdi-cash-multiple</v-icon>Finance
              </v-tab>
              <v-tab :to="{ name: 'customer-profile-integrations' }">
                <v-icon left>mdi-cog-outline</v-icon>Integrations
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>

        <div class="pa-5">
          <router-view
            v-bind:customer="customer"
            :appColor="appColor"
          ></router-view>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    appColor: {
      type: String,
    },
  },
  computed: {
    customer() {
      return this.$store.state.customers["customer"];
    },
  },
};
</script>

<style>
.v-data-table__wrapper > table > tbody > tr:hover {
  background: inherit !important;
}
</style>
